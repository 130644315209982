import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getActivePlugins } from "./pluginManager";
import { getActiveTemplate } from "./templateManager";

function App() {
  const template = getActiveTemplate();
  const plugins = getActivePlugins();
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRoutes = async () => {
      const loadedRoutes = [];

      const homePageComponent = await import(
        `./templates/${template}/pages/HomePage`
      );
      loadedRoutes.push({
        path: "/",
        element: homePageComponent.default,
      });

      for (const plugin of plugins) {
        if (plugin.routes) {
          for (const route of plugin.routes) {
            const component = await import(
              `./templates/${template}/pages/${route.component}`
            );
            loadedRoutes.push({
              path: route.path,
              element: component.default,
            });
          }
        }
      }

      setRoutes(loadedRoutes);
      setLoading(false);
    };

    loadRoutes();
  }, [template, plugins]);

  if (loading) return;

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.element />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
