let activePlugins = [];

try {
  const config = require("./config.json");
  const plugins = config.plugins;

  const areDependenciesSatisfied = (plugin) => {
    if (!plugin.dependencies) return true;
    return plugin.dependencies.every(
      (dependencyName) =>
        plugins[dependencyName] && plugins[dependencyName].active
    );
  };

  Object.keys(plugins).forEach((pluginName) => {
    const plugin = plugins[pluginName];
    if (plugin.active) {
      if (areDependenciesSatisfied(plugin)) {
        activePlugins.push(plugin);
      } else {
        console.error(
          `Impossible d'activer le plugin '${plugin.name}' car une ou plusieurs de ses dépendances ne sont pas activées.`
        );
      }
    }
  });
} catch (error) {
  console.error(
    "Erreur lors de la lecture du fichier de configuration.",
    error
  );
}

export const getActivePlugins = () => {
  return activePlugins;
};
